@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global Font and Body Settings */
body {
  font-family: 'Lato', sans-serif; /* Default font for the body */
  font-size: 16px; /* Base font size */
  line-height: 1.6; /* Line height for readability */
  margin: 0;
  padding: 0;
  background-color: #1a202c; /* Dark gray background */
  color: #f7fafc; /* Light text color */
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Dark Background and Text Colors */
.bg-gray-900 {
  background-color: #1a202c; /* Primary dark background */
}

.text-gray-100 {
  color: #f7fafc; /* Light text */
}

.text-gray-400 {
  color: #a0aec0; /* Muted light gray text */
}

.bg-gray-800 {
  background-color: #2d3748; /* Slightly lighter gray for headers, footers */
}

.bg-gray-700 {
  background-color: #4a5568; /* Darker gray for buttons or cards */
}

/* Headings */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Playfair Display', serif; /* Elegant serif font for headings */
  font-weight: 700; /* Bold weight for headings */
  color: #63b3ed; /* Light blue for headings */
  margin-top: 0; /* Ensure no top margin on headings by default */
}

h1 {
  font-size: 2.5rem; /* Large heading size */
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

/* Links */
a {
  color: #63b3ed; /* Light blue */
  text-decoration: none;
  transition: color 0.2s ease;
}

a:hover {
  color: #3182ce; /* Brighter blue on hover */
}

/* Buttons */
button {
  font-family: 'Lato', sans-serif; /* Consistent font for buttons */
  background-color: #4a5568; /* Dark button background */
  color: #f7fafc; /* Light text color */
  padding: 0.75rem 1.5rem; /* Padding for better spacing */
  border-radius: 0.375rem; /* Rounded corners */
  border: none; /* Remove default border */
  transition: background-color 0.3s ease;
  cursor: pointer;
}

button:hover {
  background-color: #2d3748; /* Slightly darker on hover */
}

button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(49, 130, 206, 0.6); /* Blue focus ring */
}

/* Inputs and Textareas */
input, textarea {
  font-family: 'Lato', sans-serif; /* Consistent font */
  background-color: #2d3748; /* Dark input background */
  color: #f7fafc; /* Light text */
  border: 1px solid #4a5568; /* Darker border */
  border-radius: 0.375rem; /* Rounded corners */
  padding: 0.5rem; /* Padding for better spacing */
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

input::placeholder, textarea::placeholder {
  color: #a0aec0; /* Light gray for placeholder text */
}

input:focus, textarea:focus {
  outline: none;
  border-color: #3182ce; /* Light blue border on focus */
  box-shadow: 0 0 0 2px rgba(49, 130, 206, 0.6); /* Blue focus shadow */
}

/* Labels */
label {
  font-family: 'Lato', sans-serif; /* Consistent font */
  color: #f7fafc; /* Light text */
  font-weight: 600; /* Slightly bold */
  margin-bottom: 0.5rem;
}

/* Containers */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

/* Cards */
.card {
  background-color: #2d3748; /* Dark card background */
  color: #f7fafc; /* Light text */
  border-radius: 0.5rem; /* Rounded corners */
  padding: 1.5rem; /* Internal padding */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-0.5rem); /* Lift effect */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Stronger shadow */
}

/* Footer */
footer {
  background-color: #2d3748; /* Dark footer background */
  color: #a0aec0; /* Muted gray text */
  padding: 1.5rem 0;
  text-align: center;
}

/* Lists */
ul, ol {
  padding-left: 1.5rem; /* Indent for lists */
}

li {
  margin-bottom: 0.5rem; /* Space between list items */
}

/* Forms */
form {
  background-color: #1a202c; /* Match the dark background */
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

form input, form textarea {
  margin-bottom: 1rem; /* Space between fields */
}

/* Tables */
table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 0.75rem;
  text-align: left;
}

th {
  background-color: #2d3748; /* Dark header background */
  color: #f7fafc; /* Light text */
  font-weight: 600;
}

td {
  background-color: #1a202c; /* Slightly darker background for rows */
  color: #f7fafc;
}

tr:nth-child(even) td {
  background-color: #2d3748; /* Alternate row color */
}
